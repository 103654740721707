import './footer.scss'

function Footer() {
  return (
    <div className="Footer">
      <span className="cp">
        <div>Made by Bryan Min</div>
        <div>Last Updated:  May 21, 2024</div>
      </span>
    </div>
  )
}

export default Footer;